import SignIn from './modules/auth/pages/SignIn';
import SignInVindix from './modules/auth/pages/SignInVindix';
import SignInRetailer from './modules/auth/pages/SignInRetailer';
import ForgotPage from "./modules/auth/pages/Forgot";
import ForgotPageVindix from "./modules/auth/pages/Forgot/vindix";
import ForgotPageRetailer from "./modules/auth/pages/Forgot/retailer";
import Items from "./modules/items/pages/Items";
import ItemsVindix from "./modules/items/pages/ItemsVindix";
import ItemsRetailer from "./modules/items/pages/ItemsRetailer";
import ProductsPage from './modules/products/pages/products';
import ProductsRetailer from './modules/products/pages/productsRetailer';
import ProductsDetails from './modules/items/pages/ItemDetails';
import ProductsDetailsRetailer from './modules/items/pages/ItemDetailsRetailer';
import ProductsDetailsVindix from './modules/items/pages/ItemDetailsVindix';
import DashboardRetailer from './modules/dashboard/pages/dashboardRetailer';
import Dashboard from './modules/dashboard/pages/dashboard';
import SuppliersPage from './modules/suppliers/pages/suppliersRetailer';
import ReportsPage from './modules/reports/pages/reportsRetailer';
import ReportsVindixPage from './modules/reports/pages/reportsVindix';
import DigitalLinks from "./modules/digitalLinks/index";
import DigitalLinksVindix from "./modules/digitalLinks/indexVindix";
import ItemDetailsFormPage from "./modules/items/pages/ItemDetailsForm";
import ItemDetailsVindixFormPage from "./modules/items/pages/ItemDetailsVindix";

const components = {
  pim: {
    login: SignIn,
    forgot: ForgotPage,
    items: Items,
    products: ProductsPage,
    dashboard: Dashboard,
    productDetails: ProductsDetails,
    itemForm: ItemDetailsFormPage,
    defaultRoute: 'DASHBOARD',
    style: './modules/common/styles/globalSupplier.scss',
    suppliers: SuppliersPage,
    reports: ReportsPage,
    digitalLinks: DigitalLinks
  },
  vindix: {
    login: SignInVindix,
    forgot: ForgotPageVindix,
    items: ItemsVindix,
    products: ProductsPage,
    itemForm: ItemDetailsVindixFormPage,
    dashboard: Dashboard,
    productDetails: ProductsDetailsVindix,
    defaultRoute: 'ITEMS',
    style: './modules/common/styles/globalVindix.scss',
    suppliers: SuppliersPage,
    reports: ReportsVindixPage,
    digitalLinks: DigitalLinksVindix
  },
  retailer: {
    login: SignInRetailer,
    forgot: ForgotPageRetailer,
    items: ItemsRetailer,
    products: ProductsRetailer,
    dashboard: DashboardRetailer,
    productDetails: ProductsDetailsRetailer,
    defaultRoute: 'DASHBOARD',
    style: './modules/common/styles/globalRetailer.scss',
    suppliers: SuppliersPage,
    reports: ReportsPage,
    digitalLinks: DigitalLinks
  },
};

export default components;

import authAxios from "../../../../helpers/authAxios";
import { Checkbox, Pagination, Table } from "antd";
import { FC, useEffect, useState } from "react";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import styles from "./activeProductsList.module.scss";
import { useActions } from "../../../../hooks/useActions";
import ReportsFilter from "../ReportsFilter/vindix";
import { DeletionActivateDeactiveProductProps } from "../../types";
import { ReactComponent as ActiveProductIcon } from "../../../common/icons/activeProduct.svg";
import {
    getItemInfosSupplier
} from "../../../../api/items";
import ProductItemsTable from "../ProductItemsTable";
import ProductHierarchy from "../../../common/components/ProductHierarchy";
import noImage from "../../../common/icons/noImage.png"
import { useNavigate } from "react-router-dom";

interface ProductListProps {
    title?: string;
    showImages?: boolean;
    showCheckbox?: boolean;
    size: number;
}

const ReportsList: FC<ProductListProps> = ({
    title,
    showImages,
    showCheckbox,
    size
}): JSX.Element => {
  const dispatch = useAppDispatch();

  const {
    setAdditionalFilter,
    setActiveProductsForDeactivationDeletion,
    setIsOpenProductFormForView,
    cleareDraftProductStore,
    setIsOpenDraftProductsFormModal,
  } = useActions();

  const {
    isLoading,
  } = useAppSelector((state) => state.draftItems);

  const {
    itemInfos,
    totalItems
  } = useAppSelector((state) => state.itemInfos);
  
  const { isChangeProductsDeletionActivationActions } = useAppSelector(
    (state) => state.modals
  );

  const { userCompanyId } = useAppSelector((state) => state.companies);
  const { user } = useAppSelector((state) => state.auth);
  const filter = useAppSelector((state) => state.additionalFilter);
  const navigate = useNavigate();
  const [supplierData, setSupplierData] = useState([]);
  title = title ?? 'Products';

  useEffect(() => {
    if (!user?.is_admin) {
      dispatch(getItemInfosSupplier(userCompanyId as number, {...filter}));
    } else {
      dispatch(getItemInfosSupplier(userCompanyId as number, {...filter}));
    }
  }, [
    dispatch,
    filter,
    isChangeProductsDeletionActivationActions,
    user?.is_admin,
    userCompanyId,
  ]);

  const fieldSections = {
    'main': [
        'gtin',
        'informationProviderGLN',
        'informationProviderName',
        'targetMarket',
        'targetMarketName',
        'productType',
        'gdsnRegistryStatus',
        'gtinName',
        'brandName',
        'shortDescription',
        'functionalName',
        'productDescription',
        'isBaseUnit',
        'isConsumerUnit',
        'isDispatchUnit',
        'isInvoiceUnit',
    ],
    'measurements': [
        'packagingMarkedReturnable',
        'nonGTINPalletHi',
        'nonGTINPalletTi',
        'width',
        'height',
        'depth',
        'volume',
        'grossWeight',
        'netWeight'
    ]
}

  const getColumns = () => {
    let columns = [];

    columns = columns.concat([
        {
            title: "GTIN",
            dataIndex: "gtin",
            key: "gtin",
        },
        {
            title: "NAME",
            dataIndex: "gtinName",
            key: "gtinName",
        },
        {
            title: "SUPPLIER",
            dataIndex: "informationProviderName",
            key: "informationProviderName",
        },
        {
            title: "MARKET NAME",
            dataIndex: "targetMarketName",
            key: "targetMarketName",
        },
        {
            title: "BRAND",
            dataIndex: "brandName",
            key: "brandName",
        },
        {
            title: "PRODUCT TYPE",
            dataIndex: "productType",
            key: "productType",
        },
        {
            title: "STATUS",
            dataIndex: "gdsnRegistryStatus",
            key: "gdsnRegistryStatus",
        },
        {
            title: "NET CONTENT",
            dataIndex: "netContent",
            key: "netContent",
        }
    ])

    return columns;
  };

  const getDataSource = () => {
    console.log(itemInfos)
    return itemInfos?.map(
      ({ gtin, informationProviderGLN, 
        informationProviderName, targetMarket, targetMarketName, 
        productType, gdsnRegistryStatus, gtinName,
        brandName, functionalName, netContent }) => {
        return {
          gtin: (
            <div
              className={styles.gtin}
              onClick={() => {
                // setIsOpenProductFormForView(true);
                // cleareDraftProductStore();
                //dispatch(getRetailerProductByGtin(gtin));
                //navigate(`/items/${gtin}/details`);
                // setIsOpenDraftProductsFormModal(true);
              }}
            >
              {gtin}
            </div>
          ),
          gtinName: (
            <div>
              {gtinName}
            </div>
          ),
          gln: (
            <div>
              {informationProviderGLN}
            </div>
          ),
          informationProviderName: (
            <div>{informationProviderName}</div>
          ),
          targetMarket: (
            <div>{targetMarket}</div>
          ),
          targetMarketName: (
            <div>{targetMarketName}</div>
          ),
          productType: (
            <div>{productType}</div>
          ),
          gdsnRegistryStatus: (
            <div>{gdsnRegistryStatus}</div>
          ),
          brandName: (
            <div>{brandName}</div>
          ),
          netContent: (
            <div>{netContent}</div>
          ),
          functionalName: (
            <div>{functionalName}</div>
          ),
        };
      }
    );
  };

  const getScroll = () => {
    let s = size !== undefined && size !== null ?
        `calc(100vh - ${size}px)` :
        `300px`;
    return s;
  }

  return (
    <>
      <ReportsFilter
        title={title}
        totalDraftProductsCount={totalItems}
        countCheckedActiveProducts={
            totalItems
        }
      />
      <div className={styles.tableWrapper}>
        <Table
          className={styles.table}
          locale={{
            emptyText: (
              <div>
                <ActiveProductIcon className={styles.emptyIcon} />
                <div>Not products activated</div>
              </div>
            ),
          }}
          dataSource={getDataSource()}
          columns={getColumns()}
          pagination={false}
          // calc(-475px + 100vh)
          // calc(-294px + 100vh)
          scroll={{ y: getScroll() }}
          loading={isLoading}
        />
      </div>
      <div className={styles.footerWrapper}>
        <Pagination
          disabled={totalItems === 0}
          className={styles.pagination}
          current={filter.page}
          onChange={(page, perPage) => {
            if (perPage) {
              dispatch(setAdditionalFilter({ page, paginate: perPage }));
              //setActiveProductsForDeactivationDeletion([]);
            } else {
              dispatch(setAdditionalFilter({ page }));
              setActiveProductsForDeactivationDeletion([]);
            }
          }}
          total={totalItems || 15}
          showSizeChanger={true}
          pageSizeOptions={["15", "20", "50", "100"]}
        />
      </div>
    </>
  );
};

export default ReportsList;

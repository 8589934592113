/* eslintdisable reacthooks/exhaustivedeps */
import { FormikProps, withFormik } from "formik";
import * as yup from "yup";
import { SignInProps } from "../../types";
import InputField from "../../../common/components/InputField";
import styles from "./signInForm.module.scss";
import { Button, Checkbox, Row, Col } from "antd";
import { singIn } from "../../../../api/auth";
import { NavLink } from "react-router-dom";
import { RoutesNames } from "../../../../routes";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { useActions } from "../../../../hooks/useActions";
import { LogInTypes } from "../../constants";
import VindixLogo from "../../../common/icons/vindixlogocolor.png";
import VindixSubtitle from "../../../common/icons/vindixloginsubtitle.png";

interface SignInFormOwnProps {
  dispatch: (value: any) => void;
}

const SignInForm = ({
  handleSubmit,
  values,
  setFieldTouched,
  touched,
  errors,
  setFieldValue,
  dispatch,
}: FormikProps<SignInProps> & SignInFormOwnProps) => {
  const { validationError } = useAppSelector((store) => store.auth);
  const { cleareAuthValidationError } = useActions();

  return (
    <form
      onSubmit={handleSubmit}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleSubmit();
        }
      }}
      className={styles.wineForm}
    >
        
        <Row align="middle">
            <Col span={24}>
                <Row justify="center" align="middle">
                    <img src={VindixLogo} width="300px" />
                </Row>
                <Row justify="center" align="middle">
                    <img src={VindixSubtitle} style={{paddingTop: '25px'}} />
                </Row>
            </Col>
            <Col span={24}><h1 className={styles.formTitle}>LOGIN</h1></Col>
            <Row className={styles.fields}>
                <Col span={24} >    
                    <Col span={12} offset={6}>
                        <InputField
                        title="Email"
                        className={styles.field}
                        titleClassName={styles.fieldTitleWine}
                        value={values.email}
                        required={false}
                        style={{color: '#000000'}}
                        errorMessage={
                            (touched.email && errors.email) ||
                            (validationError && (validationError.email as any))
                        }
                        onBlur={() => setFieldTouched("email")}
                        onChange={(event: any) => {
                            setFieldValue("email", event.target.value);
                            cleareAuthValidationError();
                        }}
                        />
                    </Col>
                    <Col span={12} offset={6}>
                        <InputField
                        title="Password"
                        titleClassName={styles.fieldTitleWine}
                        value={values.password}
                        type="password"
                        required={false}
                        passwordEye={true}
                        errorMessage={
                            (touched.password && errors.password) ||
                            (validationError && (validationError.password as any))
                        }
                        onBlur={() => setFieldTouched("password")}
                        onChange={(event: any) => {
                            setFieldValue("password", event.target.value);
                            cleareAuthValidationError();
                        }}
                        />
                    </Col>
                    <Col span={12} offset={6}>
                        <Checkbox
                            className={styles.checkbox}
                            onChange={(event) => setFieldValue("isRemember", event.target.checked)}
                        >
                            <div className={styles.checkboxTitle}>Remember Me</div>
                        </Checkbox>
                    </Col>
                </Col>
            </Row>
            <Col span={12} offset={6} style={{textAlign: 'center'}}>
                <div className={styles.buttonGroup}>
                    <Button
                        className={styles.button}
                        type="primary"
                        onClick={() => handleSubmit()}
                        >Login
                    </Button>
                </div>
                <NavLink to={RoutesNames.FORGOT} className={styles.buttonLink}>
                    Forgot Password
                </NavLink>
            </Col>
                <Col span={12} offset={6}>
                <div className={styles.poweredBy}>Powered By: <a href="https://trustedsource.co.za/" target="_blank">TrustedSource</a> & <a href="https://wearebluecode.com/" target="_blank">Blue Code.</a></div>
                </Col>
        </Row>
    </form>
  );
};

const SignUpFormWithFormik = withFormik<SignInFormOwnProps, SignInProps>({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    email: "",
    password: "",
    isRemember: false,
    showLogo: true
  }),
  handleSubmit: (values, { setErrors, props: { dispatch } }) => {
    dispatch(singIn(LogInTypes.USER, values, setErrors));
  },
  validationSchema: yup.object().shape({
    email: yup
      .string()
      .email("The email is not valid")
      .required("This field is required"),
    password: yup
      .string()
      .min(6, "Must be more than 6 characters")
      .max(32, "Must be less than 32 characters")
      .required("This field is required"),
  }),
  displayName: "SignInForm",
})(SignInForm);

export default SignUpFormWithFormik;

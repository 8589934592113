import { useState, useEffect } from 'react';
import { Input, Col, Row, Select, Button, Alert } from 'antd';
import { InputProps } from 'antd/lib/input';
import { Option } from 'antd/lib/mentions';
import InputFieldTitle from '../InputFieldTitle';
import InputField from '../InputField';
import { PlusOutlined } from '@ant-design/icons';
import { ReactComponent as TrashIcon } from '../../icons/trash.svg';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { SelectData } from '../../types';
import { getLanguages } from '../../../../api/filter';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';

type MultiLanguageInputProps = {
    element?: any;
    dinamicFormData?: any;
    setDinamicFormData?: any;
    dinamicFormDataError?: any;
} & InputProps;

const MultiLanguageInput = ({
    element,
    dinamicFormData,
    setDinamicFormData,
    dinamicFormDataError,
    ...props
}: MultiLanguageInputProps) => {
    const { languagesList } = useAppSelector((state) => state.optionLists);
    const [multiLanguageData, setMultiLanguageData] = useState<any[]>([
        {
          key: '',
          data: {
                languageText: '',
                languageCode: ''
            }
        }
    ]);
    
    const dispatch = useAppDispatch();
    useEffect(() => {
      if(languagesList !== undefined && languagesList !== null && languagesList.length == 0) {
        dispatch(getLanguages());
      }
    }, [languagesList])

    useEffect(() => {
    //Detects when multiLanguageData changes and update dinamicFormData
        let data = { ...dinamicFormData };

        Object.keys(multiLanguageData).map((itemLanguage: any) => {
          if(data[itemLanguage] !== undefined) {
            data[itemLanguage] = multiLanguageData[itemLanguage];
            setDinamicFormData(data);
            
          }
        });
    }, [multiLanguageData]);

    useEffect(() => {
        getLanguageTextField()
    }, [dinamicFormData])

     const getLanguageTextField = () => {
    //To initialize languages fields
        if(multiLanguageData.length > 0) {
          if(multiLanguageData[0].key === '') {
            let copyOfMultiLanguageData = {...multiLanguageData}

            if(dinamicFormData.isLanguageKeys !== undefined && dinamicFormData.isLanguageKeys.length > 0) {
              dinamicFormData.isLanguageKeys.map((name: any) => {
                if(dinamicFormData[name] !== undefined && typeof dinamicFormData[name] === 'string') {
                  let currentData = JSON.parse(dinamicFormData[name]);

                  if(currentData.length > 0) {
                    copyOfMultiLanguageData[name] = currentData;
                }
            } else if(dinamicFormData[name] === null) {
              copyOfMultiLanguageData[name] = [{'languageText': '', 'languageCode': '' }];
          }
      });
              setMultiLanguageData(copyOfMultiLanguageData);
          }      
      }
    }    
    };

    const setLanguageTextCodeMultiple = (name: any, index: any, fieldKey: string, value: any) => {
      let copyOfMultiLanguageData = {...multiLanguageData}

      if(fieldKey === 'text') {
        if(copyOfMultiLanguageData[name] !== undefined) {
            copyOfMultiLanguageData[name][index]['languageText'] =  value 
        } else {
            copyOfMultiLanguageData[name] = [{'languageText': value, 'languageCode': ''}]
        }
      } else if(fieldKey === 'code') {
          if(copyOfMultiLanguageData[name] !== undefined) {
            copyOfMultiLanguageData[name][index]['languageCode'] =  value
        } else {
            copyOfMultiLanguageData[name] = [{'languageText': '', 'languageCode': value}]
        }
      }

      setMultiLanguageData(copyOfMultiLanguageData);
    }

    const addNewLanguageLine = (name: any) => {
        let copyOfMultiLanguageData = {...multiLanguageData}
        copyOfMultiLanguageData[name][multiLanguageData[name].length] = {'languageText': '', 'languageCode': ''};
        setMultiLanguageData(copyOfMultiLanguageData)
    }

    const removeLanguageLine = (name: any, index: any) => {
        let copyOfMultiLanguageData = { ...multiLanguageData }

        if(copyOfMultiLanguageData[name] !== undefined) {
            if(copyOfMultiLanguageData[name][index] !== undefined) {
                copyOfMultiLanguageData[name].splice(index, 1)
                setMultiLanguageData(copyOfMultiLanguageData)
            }
        }
    }
    
    return (
        <div>
            {element?.is_language?              
              <div>
                {dinamicFormDataError[element.key] && (
                  <Row style={{paddingBottom: '20px'}}>
                    <Alert message="For each field you have to fill in text and select language. Please check the data again." type="error" closable />
                  </Row>
                )}
                <Row  justify="start" >
                    <Col span={4}>{element.name}</Col>
                    <Col xs={{span: 8}} lg={{span: 5}}>
                        <InputField
                            value={multiLanguageData[element.key] !== undefined?multiLanguageData[element.key][0].languageText: ''}
                            placeholder={element.name}
                            required={false}
                            
                            onChange={(e) => {
                                setLanguageTextCodeMultiple(element.key, '0', 'text', e.target.value);
                            }}
                      />
                    </Col>
                    <Col xs={{span: 8}} lg={{span: 5}}>
                      <InputFieldTitle
                          title=""
                          required={false}
                          
                        >
                          <Select
                            value={multiLanguageData[element.key] !== undefined?multiLanguageData[element.key][0].languageCode: ''}
                            size="middle"
                            placeholder="Language"
                            style={{paddingLeft: '8px'}}
                            onChange={(e) => {
                                setLanguageTextCodeMultiple(element.key, '0', 'code', e);
                            }}
                          >
                            {languagesList.map((sortOption: SelectData) => (
                              <Option key={sortOption.name} value={`${sortOption.value}`}>
                                {sortOption.name}
                              </Option>
                            ))}
                          </Select>
                      </InputFieldTitle>
                    </Col>
                  
                    {element.is_multiple?
                    <Col span={1}>
                        <Button
                            style={{ borderRadius: '8px', padding: '0 8px', marginLeft: '8px'}}
                            onClick={() => addNewLanguageLine(element.key)}
                        >
                            <PlusOutlined />
                        </Button>
                    </Col>
                    :''}
                  
                  
                </Row>

              {element.is_multiple?
                <div>
                  <div>
                    {multiLanguageData[element.key] !== undefined ?
                      Object.entries(multiLanguageData[element.key]).map((value: any, index: any) => {
                        if(multiLanguageData[element.key].length > 1 && index > 0) {
                          return ( 
                            <Row  justify="start" >
                              <Col span={4}>{element.name}</Col>
                              <Col xs={{span: 8}} lg={{span: 5}}>
                               <InputField
                                    value={value[1].languageText !== undefined?value[1].languageText: ''}
                                    placeholder={element.name}
                                    required={false}
                                  
                                    onChange={(e) => {
                                      setLanguageTextCodeMultiple(element.key, index, 'text', e.target.value);
                                    }}
                              />
                              </Col>
                              <Col xs={{span: 8}} lg={{span: 5}}>
                              <InputFieldTitle
                                title=""
                                required={false}
                                
                              >
                                <Select
                                    value={value[1].languageCode !== undefined?value[1].languageCode: ''}
                                    size="middle"
                                    placeholder="Language"
                                    style={{paddingLeft: '8px'}}
                                    onChange={(e) => {
                                        setLanguageTextCodeMultiple(element.key, index, 'code', e);
                                    }}
                                >
                                  {languagesList.map((sortOption: SelectData) => (
                                    <Option key={sortOption.name} value={`${sortOption.value}`}>
                                      {sortOption.name}
                                    </Option>
                                  ))}
                                </Select>
                            </InputFieldTitle>
                            </Col>
                            <Col span={1}>
                                <Button
                                    style={{ borderRadius: '8px', padding: '2px 5px', marginLeft: '8px'}}
                                    onClick={() => removeLanguageLine(element.key, index)}
                                >
                                    <TrashIcon
                                        
                                    />
                                </Button>
                            </Col>
                            </Row>
                          )  
                        }
                        
                      })
                    :''}
                  </div>
                </div>
              :''}
              </div>
            
          :''} 
        </div>
    );
};

export default MultiLanguageInput;

import { notification } from "antd";
import Input from "antd/lib/input/Input";
import { FC } from "react";
import { uploadFile } from "../../../../api/upload";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import styles from "./uploader.module.scss";

const Uploader: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const validateFile = (file: File) => {
    if (!file.name.includes('.xls')) {
      notification.error({
        message: "The format of file is not supported.",
        description: "Please use file xls/xlsx",
        duration: 5,
      });
      return false;
    }

    if (file.size >= 2000000) {
      notification.error({
        message: "The size of the file is too big.",
        description: "Please upload an file not bigger than 2mb",
        duration: 5,
      });
      return false;
    }

    return file;
  };

  const imageSelectedHandler = (event: any) => {
    try {
      const file = validateFile(event.target.files[0]);
      let reader = new FileReader();
      reader.readAsDataURL(file as any);
      reader.onload = () => {
        dispatch(uploadFile(file as File))
          .then(() => {
            notification.info({
              message: "File upload",
              description: "File uploaded successfully",
              duration: 5,
            });
          })
          .catch(() => {
            notification.error({
              message: "File upload",
              description: "File upload error",
              duration: 5,
            });
          })
      };
    } catch (error) {
      return false;
    }
  };

  return (
    <div>
      <label className={styles.inputLabel}>
        Upload file
        <Input
          accept=".xls,.xlsx"
          className={styles.inputFile}
          value={""}
          type="file"
          required={false}
          onChange={imageSelectedHandler}
        />
      </label>
    </div>
  );
};

export default Uploader;

import { useState, useEffect } from 'react';
import { Input, Col, Row, Select, Button, Alert } from 'antd';
import { InputProps } from 'antd/lib/input';
import { Option } from 'antd/lib/mentions';
import InputFieldTitle from '../InputFieldTitle';
import InputField from '../InputField';
import { PlusOutlined } from '@ant-design/icons';
import { ReactComponent as TrashIcon } from '../../icons/trashGrey.svg';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { SelectData } from '../../types';
import { getAllergenCodes } from '../../../../api/filter';
import { getLevelOfContainmentCodes } from '../../../../api/filter';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';

type AllergenInputProps = {
    itemAllergens?: any;
    setItemAllergens?: any;
    item?: any;
    dinamicFormDataError?: any;
} & InputProps;

const Allergen = ({
    itemAllergens,
    setItemAllergens,
    item,
    dinamicFormDataError,
    ...props
}: AllergenInputProps) => {
    const { allergenCodesList, levelOfContainmentList} = useAppSelector(
        (state) => state.optionLists
    );
    
    const [allergenCodeWine, setAllergenCodeWine] = useState({name: '', value: ''});

    const [allergenCode, setAllergenCode] = useState('');
    const [allergenName, setAllergenName] = useState('');
    const [levelOfContainmentCode, setLevelOfContainmentCode] = useState('');
    const [levelOfContainmentName, setLevelOfContainmentName] = useState('');
    const { userCompanyType } = useAppSelector((state) => state.companies);
    const dispatch = useAppDispatch();

    useEffect(() => {
      if(item !== null && item !== undefined && item.item_allergens !== undefined && item.item_allergens.length > 0) {
        setItemAllergens(item.item_allergens)
      }
    }, []);

    useEffect(() => {
      if(allergenCodesList !== undefined && allergenCodesList !== null && allergenCodesList.length == 0) {
         dispatch(getAllergenCodes());
         
      }
      setAllergenCodeWine(allergenCodesList.find((code) => {return code.value == "AU"}));

    },[allergenCodesList]);

    useEffect(() => {
      if(levelOfContainmentList !== undefined && levelOfContainmentList !== null && levelOfContainmentList.length == 0) {
        dispatch(getLevelOfContainmentCodes());
      }
    },[levelOfContainmentList]);

    const addAllergenLine = () => {
    setItemAllergens( // Replace the state
          [ // with a new array
            ...itemAllergens, // that contains all the old items
            {allergenCode, allergenName, levelOfContainmentCode, levelOfContainmentName} // and one new item at the end
            ]
          );
    };

    const removeAllergenLine = (allergen: any) => {
        setItemAllergens(
          itemAllergens.filter(a => a.allergenCode !== allergen.allergenCode)
          );
    }

    return (<div>
        <>
          <Row style={{paddingBottom: '20px'}}>
            {dinamicFormDataError !== undefined && dinamicFormDataError['allergen-codes'] && (
              <Alert message="Please enter a Level of containment and an Allergen code." type="error" closable />
            )}
          </Row>
          <Row gutter={16}>
              <Col span={4} style={{fontWeight: '700'}}>Level Of Containment</Col>
              <Col xs={{span: 7}} lg={{span: 5}}>
                <InputFieldTitle
                  title=""
                  required={false}
                >
                  <Select
                    size="middle"
                    placeholder="Level Of Containment List"
                    onChange={(value, data: any) => {
                      setLevelOfContainmentCode(value);
                      setLevelOfContainmentName(data.key)
                    }}
                  >
                    {levelOfContainmentList.map((sortOption: SelectData) => (
                      <Option key={sortOption.name} value={`${sortOption.value}`}>
                        {sortOption.name}
                      </Option>
                    ))}
                  </Select>
                </InputFieldTitle>
                </Col>
            <Col span={3} style={{fontWeight: '700'}}>Allergen Code</Col>
            <Col xs={{span: 7}} lg={{span: 5}}>
            <InputFieldTitle
              title=""
              required={false}
            >
              <Select
                size="middle"
                placeholder="Allergen Type Code"
                onChange={(value, data: any) => {
                  setAllergenCode(value)
                  setAllergenName(data.key)
                }}
              >
                {userCompanyType === 'wine' && allergenCodeWine !== undefined && allergenCodeWine !== null ?
                  <Option key={allergenCodeWine.name} value={`${allergenCodeWine.value}`}>
                    {allergenCodeWine.name}
                  </Option>
                  :allergenCodesList.map((sortOption: SelectData) => (
                  <Option key={sortOption.name} value={`${sortOption.value}`}>
                    {sortOption.name}
                  </Option>
                ))}
              </Select>
            </InputFieldTitle>
            </Col>

            <Col span={2}>
              <Button
                  style={{ borderRadius: '8px', padding: '0 8px'}}
                  onClick={addAllergenLine}
                  disabled={allergenName && levelOfContainmentName?false:true}
              >

                  <PlusOutlined />
              </Button>
            </Col>
          </Row>
          <div>
          {itemAllergens.map((allergen: any, index) => {
            return  <Row justify="start" style={{paddingBottom: '10px'}} gutter={16}>
                      <Col xs={{span: 11}} lg={{span: 9}}>{allergen.levelOfContainmentName}</Col>
                      <Col xs={{span: 11}} lg={{span: 8}}>{allergen.allergenName}</Col>
                      <Col span={2}>
                          <Button
                              style={{ borderRadius: '8px', padding: '2px 5px'}}
                              onClick={() => removeAllergenLine(allergen)}
                          >
                              <TrashIcon />
                          </Button>
                      </Col>
                    </Row>
          })}
          </div>
        </>
    </div>)
}

export default Allergen;
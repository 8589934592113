import { Dispatch } from '@reduxjs/toolkit';
import authAxios from '../../helpers/authAxios';
import {
    GetItemsRequestData,
    GetItemsResponseData,
    Item,
} from '../../modules/items/types';
import { draftItemsSlice } from '../../redux/reducers/draftItems';
import { itemInfoSlice } from '../../redux/reducers/itemInfo';
import { itemInfosSlice } from '../../redux/reducers/itemInfos';
import { itemFormSlice } from '../../redux/reducers/itemForm';
import { notification } from 'antd';
import { activeItemsSlice } from '../../redux/reducers/activeItems';
import { convertToFormData } from '../../helpers';
import { modalsSlice } from '../../redux/reducers/modals';
import { filterSlice } from '../../redux/reducers/filter';
import { additionalFilterSlice } from '../../redux/reducers/additionalFilter';
import parse from 'html-react-parser';

export const getAllDraftItemsForView = (props: GetItemsRequestData) => {
    return async (dispatch: Dispatch): Promise<void> => {
        try {
            dispatch(draftItemsSlice.actions.getAllDraftItemsPending());
            let responseData: GetItemsResponseData;
            const checkIdsParams =
                props.sortedByFilterIds?.filterIds.length === 0;
            const checkDateParams = !props.dateStart || !props.dateEnd;

            const requestDataWithParams = () => {
                if (!checkIdsParams && !checkDateParams) {
                    return {
                        [props.sortedByFilterIds?.sortByName as any]: props
                            .sortedByFilterIds?.filterIds as number[],
                        date_start: !checkDateParams ? props.dateStart : null,
                        date_end: !checkDateParams ? props.dateEnd : null,
                    };
                } else {
                    if (!checkIdsParams) {
                        return {
                            [props.sortedByFilterIds?.sortByName as any]: props
                                .sortedByFilterIds?.filterIds as number[],
                        };
                    }

                    if (!checkDateParams) {
                        return {
                            date_start: !checkDateParams
                                ? props.dateStart
                                : null,
                            date_end: !checkDateParams ? props.dateEnd : null,
                        };
                    }
                }
            };

            if (checkIdsParams && checkDateParams) {
                const { data } = await authAxios(dispatch).post(
                    `/api/draft-items-view?page=${props.page}&paginate=${props.paginate}&search=${props.search}&status=${props.status}`
                );
                responseData = data;
            } else {
                const { data } = await authAxios(dispatch).post(
                    `/api/draft-items-view?page=${props.page}&paginate=${props.paginate}&search=${props.search}&status=${props.status}`,
                    requestDataWithParams()
                );
                responseData = data;
            }

            dispatch(
                draftItemsSlice.actions.getAllDraftItemsFulfilled({
                    draftItems: responseData.data,
                    totalItems: responseData.pagination.total,
                    totalValidItems: responseData.pagination.total_validated,
                })
            );

            if (responseData.data.length === 0) {
                dispatch(
                    filterSlice.actions.setFilter({
                        page: Number(responseData.pagination.total_pages),
                        paginate: Number(responseData.pagination.per_page),
                    })
                );
            }
        } catch (error: any) {
            dispatch(
                draftItemsSlice.actions.getAllDraftItemsRejected(
                    error.data.message
                )
            );
        }
    };
};

export const getAllDraftItems = (
    userCompanyId: number,
    props: GetItemsRequestData
) => {
    return async (dispatch: Dispatch): Promise<void> => {
        try {
            dispatch(draftItemsSlice.actions.getAllDraftItemsPending());
            let responseData: GetItemsResponseData;
            const checkIdsParams =
                props.sortedByFilterIds?.filterIds.length === 0;
            const checkDateParams = !props.dateStart || !props.dateEnd;

            const requestDataWithParams = () => {
                if (!checkIdsParams && !checkDateParams) {
                    return {
                        [props.sortedByFilterIds?.sortByName as any]: props
                            .sortedByFilterIds?.filterIds as number[],
                        date_start: !checkDateParams ? props.dateStart : null,
                        date_end: !checkDateParams ? props.dateEnd : null,
                    };
                } else {
                    if (!checkIdsParams) {
                        return {
                            [props.sortedByFilterIds?.sortByName as any]: props
                                .sortedByFilterIds?.filterIds as number[],
                        };
                    }

                    if (!checkDateParams) {
                        return {
                            date_start: !checkDateParams
                                ? props.dateStart
                                : null,
                            date_end: !checkDateParams ? props.dateEnd : null,
                        };
                    }
                }
            };

            if (checkIdsParams && checkDateParams) {
                const { data } = await authAxios(dispatch).post(
                    `/api/draft-items?page=${props.page}&paginate=${props.paginate}&search=${props.search}&status=${props.status}&company_id=${userCompanyId}`
                );
                responseData = data;
            } else {
                const { data } = await authAxios(dispatch).post(
                    `/api/draft-items?page=${props.page}&paginate=${props.paginate}&search=${props.search}&status=${props.status}&company_id=${userCompanyId}`,
                    requestDataWithParams()
                );
                responseData = data;
            }

            dispatch(
                draftItemsSlice.actions.getAllDraftItemsFulfilled({
                    draftItems: responseData.data,
                    totalItems: responseData.pagination.total,
                    totalValidItems: responseData.pagination.total_validated,
                })
            );

            if (responseData.data.length === 0) {
                dispatch(
                    filterSlice.actions.setFilter({
                        page: Number(responseData.pagination.total_pages),
                        paginate: Number(responseData.pagination.per_page),
                    })
                );
            }
        } catch (error: any) {
            dispatch(
                draftItemsSlice.actions.getAllDraftItemsRejected(
                    error.data.message
                )
            );
        }
    };
};

export const createDraftItem = (
    userCompanyId: number,
    body: Item,
    formTab: any
) => {
    return async (dispatch: Dispatch): Promise<void> => {
        try {
            dispatch(draftItemsSlice.actions.cleareDraftItemsValidationError());
            dispatch(draftItemsSlice.actions.createDraftItemPending());

            const bodyFormData = convertToFormData(body);

            const { data } = await authAxios(dispatch)({
                method: 'POST',
                url: `/api/draft-items/save?&company_id=${userCompanyId}`,
                data: bodyFormData,
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            dispatch(itemFormSlice.actions.setPopup('success'));
            setTimeout(() => {
                dispatch(itemFormSlice.actions.setPopup(''));
            }, 3000);

            dispatch(draftItemsSlice.actions.createDraftItemFulfilled(data));
        } catch (error: any) {
            if (error.status === 422) {
                dispatch(itemFormSlice.actions.setPopup('gtinError'));
                setTimeout(() => {
                    dispatch(itemFormSlice.actions.setPopup(''));
                }, 3000);
            } else {
                dispatch(itemFormSlice.actions.setPopup('error'));
                setTimeout(() => {
                    dispatch(itemFormSlice.actions.setPopup(''));
                }, 3000);
            }
        }
    };
};

export const editDraftItem = (
    userCompanyId: number,
    body: Item,
    formTab?: any,
    isLastTab?: any
) => {
    return async (dispatch: Dispatch): Promise<void> => {
        try {
            dispatch(draftItemsSlice.actions.cleareDraftItemsValidationError());
            dispatch(draftItemsSlice.actions.editDraftItemPending());

            const bodyFormData = convertToFormData(body);

            const { data } = await authAxios(dispatch)({
                method: 'POST',
                url: `/api/draft-items/update/${body.id}?&company_id=${userCompanyId}`,
                data: bodyFormData,
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            if (formTab === 'main') {
                dispatch(modalsSlice.actions.setModalActiveTab('measurements'));
            }

            if (isLastTab) {
                dispatch(
                    modalsSlice.actions.setIsOpenDraftItemsFormModal(false)
                );
            }
            dispatch(itemFormSlice.actions.setPopup('success'));
            setTimeout(() => {
                dispatch(itemFormSlice.actions.setPopup(''));
            }, 3000);
            dispatch(draftItemsSlice.actions.editDraftItemFulfilled(data));
        } catch (error: any) {
            if (error.status === 422) {
                dispatch(itemFormSlice.actions.setPopup('gtinError'));
                setTimeout(() => {
                    dispatch(itemFormSlice.actions.setPopup(''));
                }, 3000);
            } else {
                dispatch(itemFormSlice.actions.setPopup('error'));
                setTimeout(() => {
                    dispatch(itemFormSlice.actions.setPopup(''));
                }, 3000);
            }
        }
    };
};

export const getDraftItemById = (id: number) => {
    return async (dispatch: Dispatch): Promise<void> => {
        try {
            dispatch(draftItemsSlice.actions.getDraftItemPending());

            const { data } = await authAxios(dispatch).get(
                `/api/draft-items/${id}`
            );

            dispatch(
                draftItemsSlice.actions.getDraftItemFulfilled({
                    draftItem: data,
                })
            );
        } catch (error: any) {
            dispatch(
                draftItemsSlice.actions.getDraftItemRejected(error.data.message)
            );
        }
    };
};

export const getItemInfoByGtin = (gtin: number) => {
    return async (dispatch: Dispatch): Promise<void> => {
        try {
            dispatch(itemInfoSlice.actions.getItemInfo());

            const { data } = await authAxios(dispatch).get(
                `/api/retailers/item/${gtin}`
            );

            dispatch(
                itemInfoSlice.actions.getItemInfoFulfilled({
                    item: data,
                })
            );
        } catch (error: any) {
            dispatch(
                draftItemsSlice.actions.getDraftItemRejected(error.data.message)
            );
        }
    };
};

export const getItemInfos = (userCompanyId: number, props: GetItemsRequestData) => {
    return async (dispatch: Dispatch): Promise<void> => {
        try {
            let responseData: GetItemsRequestData;
            const checkIdsParams = props.sortedByFilterIds?.filterIds.length === 0;
            const checkDateParams = !props.dateStart || !props.dateEnd;

            const requestDataWithParams = () => {
                if (!checkIdsParams && !checkDateParams) {
                return {
                    [props.sortedByFilterIds?.sortByName as any]: props
                    .sortedByFilterIds?.filterIds as number[],
                    date_start: !checkDateParams ? props.dateStart : null,
                    date_end: !checkDateParams ? props.dateEnd : null,
                };
                } else {
                if (!checkIdsParams) {
                    return {
                    [props.sortedByFilterIds?.sortByName as any]: props
                        .sortedByFilterIds?.filterIds as number[],
                    };
                }

                if (!checkDateParams) {
                    return {
                    date_start: !checkDateParams ? props.dateStart : null,
                    date_end: !checkDateParams ? props.dateEnd : null,
                    };
                }
                }
            };
            //dispatch(itemInfoSlice.actions.getItemInfo());

            const { data } = await authAxios(dispatch).post(
                `/api/retailer/items?page=${props.page}&paginate=${props.paginate}&search=${props.search}&company_id=${userCompanyId}`, requestDataWithParams()
            );

            dispatch(
                itemInfosSlice.actions.getItemInfos({
                    itemInfos: data.itemInfos,
                    totalItems: data.totalItems
                })
            );
        } catch (error: any) {
            // dispatch(
            //     draftItemsSlice.actions.getDraftItemRejected(error.data.message)
            // );
        }
    };
};

export const getItemInfosSupplier = (userCompanyId: number, props: GetItemsRequestData) => {
    return async (dispatch: Dispatch): Promise<void> => {
        try {
            let responseData: GetItemsRequestData;
            const checkIdsParams = props.sortedByFilterIds?.filterIds.length === 0;
            const checkDateParams = !props.dateStart || !props.dateEnd;

            const requestDataWithParams = () => {
                if (!checkIdsParams && !checkDateParams) {
                return {
                    [props.sortedByFilterIds?.sortByName as any]: props
                    .sortedByFilterIds?.filterIds as number[],
                    date_start: !checkDateParams ? props.dateStart : null,
                    date_end: !checkDateParams ? props.dateEnd : null,
                };
                } else {
                if (!checkIdsParams) {
                    return {
                    [props.sortedByFilterIds?.sortByName as any]: props
                        .sortedByFilterIds?.filterIds as number[],
                    };
                }

                if (!checkDateParams) {
                    return {
                    date_start: !checkDateParams ? props.dateStart : null,
                    date_end: !checkDateParams ? props.dateEnd : null,
                    };
                }
                }
            };
            //dispatch(itemInfoSlice.actions.getItemInfo());

            const { data } = await authAxios(dispatch).post(
                `/api/item-infos?page=${props.page}&paginate=${props.paginate}&search=${props.search}&company_id=${userCompanyId}`, requestDataWithParams()
            );

            dispatch(
                itemInfosSlice.actions.getItemInfos({
                    itemInfos: data.itemInfos,
                    totalItems: data.totalItems
                })
            );
        } catch (error: any) {
            // dispatch(
            //     draftItemsSlice.actions.getDraftItemRejected(error.data.message)
            // );
        }
    };
};

export const changeDraftItemsStatus = (body: number[]) => {
    return async (dispatch: Dispatch): Promise<void> => {
        try {
            dispatch(draftItemsSlice.actions.changeStatusDraftItemPending());

            const { data } = await authAxios(dispatch).post(
                `/api/items/change-status`,
                {
                    array_ids: body,
                    status: 1,
                }
            );

            dispatch(
                draftItemsSlice.actions.clearDraftItemsForActivationDeletion()
            );
            dispatch(
                modalsSlice.actions.setIsChangeItemsDeletionActivationActions(
                    false
                )
            );

            notification.info({
                message: 'Active status info',
                description: parse(data),
                duration: 7,
            });
        } catch (error: any) {
            if (error.status === 403) {
                notification.error({
                    message: "Selected Item can't be activated!",
                    description: 'Please, fill in all mandatory fields.',
                    duration: 6,
                });
            }
            dispatch(
                draftItemsSlice.actions.changeStatusDraftItemRejected(
                    error.data.message
                )
            );
        }
    };
};

export const deleteDraftItems = (body: number[]) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const config = {
            data: { array_ids: body },
        };

        try {
            dispatch(draftItemsSlice.actions.deleteDraftItemsPending());

            await authAxios(dispatch).delete(`/api/draft-items/delete`, config);

            dispatch(
                draftItemsSlice.actions.clearDraftItemsForActivationDeletion()
            );
            dispatch(
                modalsSlice.actions.setIsChangeItemsDeletionActivationActions(
                    false
                )
            );
        } catch (error: any) {
            dispatch(
                draftItemsSlice.actions.deleteDraftItemsRejected(
                    error.data.message
                )
            );
        }
    };
};

export const validateDraftItem = (userCompanyId: number, body: Item) => {
    return async (dispatch: Dispatch): Promise<void> => {
        try {
            dispatch(draftItemsSlice.actions.validateDraftItemPending());

            const { data } = await authAxios(dispatch).post(
                `/api/draft-items/validated/${body.id}?&company_id=${userCompanyId}`,
                body
            );

            dispatch(draftItemsSlice.actions.validateDraftItemFulfilled(data));
        } catch (error: any) {
            dispatch(
                draftItemsSlice.actions.validateDraftItemRejected(
                    error.data.message
                )
            );
        }
    };
};

export const getAllActiveItemsForView = (props: GetItemsRequestData) => {
    return async (dispatch: Dispatch): Promise<void> => {
        try {
            let responseData: GetItemsResponseData;
            const checkIdsParams =
                props.sortedByFilterIds?.filterIds.length === 0;
            const checkDateParams = !props.dateStart || !props.dateEnd;

            const requestDataWithParams = () => {
                if (!checkIdsParams && !checkDateParams) {
                    return {
                        [props.sortedByFilterIds?.sortByName as any]: props
                            .sortedByFilterIds?.filterIds as number[],
                        date_start: !checkDateParams ? props.dateStart : null,
                        date_end: !checkDateParams ? props.dateEnd : null,
                    };
                } else {
                    if (!checkIdsParams) {
                        return {
                            [props.sortedByFilterIds?.sortByName as any]: props
                                .sortedByFilterIds?.filterIds as number[],
                        };
                    }

                    if (!checkDateParams) {
                        return {
                            date_start: !checkDateParams
                                ? props.dateStart
                                : null,
                            date_end: !checkDateParams ? props.dateEnd : null,
                        };
                    }
                }
            };

            dispatch(activeItemsSlice.actions.getAllActiveItemsPending());

            if (checkIdsParams && checkDateParams) {
                const { data } = await authAxios(dispatch).post(
                    `/api/items-view?page=${props.page}&paginate=${props.paginate}&search=${props.search}&status=${props.status}`
                );

                responseData = data;
            } else {
                const { data } = await authAxios(dispatch).post(
                    `/api/items-view?page=${props.page}&paginate=${props.paginate}&search=${props.search}&status=${props.status}`,
                    requestDataWithParams()
                );

                responseData = data;
            }

            dispatch(
                activeItemsSlice.actions.getAllActiveItemsFulfilled({
                    activeItems: responseData.data,
                    totalItems: responseData.pagination.total,
                })
            );

            if (responseData.data.length === 0) {
                dispatch(
                    additionalFilterSlice.actions.setAdditionalFilter({
                        page: Number(responseData.pagination.total_pages),
                        paginate: Number(responseData.pagination.per_page),
                    })
                );
            }
        } catch (error: any) {
            dispatch(
                activeItemsSlice.actions.getAllActiveItemsRejected(
                    error.data.message
                )
            );
        }
    };
};

export const getAllActiveItems = (
    userCompanyId: number,
    props: GetItemsRequestData
) => {
    return async (dispatch: Dispatch): Promise<void> => {
        try {
            let responseData: GetItemsResponseData;
            const checkIdsParams =
                props.sortedByFilterIds?.filterIds.length === 0;
            const checkDateParams = !props.dateStart || !props.dateEnd;

            const requestDataWithParams = () => {
                if (!checkIdsParams && !checkDateParams) {
                    return {
                        [props.sortedByFilterIds?.sortByName as any]: props
                            .sortedByFilterIds?.filterIds as number[],
                        date_start: !checkDateParams ? props.dateStart : null,
                        date_end: !checkDateParams ? props.dateEnd : null,
                    };
                } else {
                    if (!checkIdsParams) {
                        return {
                            [props.sortedByFilterIds?.sortByName as any]: props
                                .sortedByFilterIds?.filterIds as number[],
                        };
                    }

                    if (!checkDateParams) {
                        return {
                            date_start: !checkDateParams
                                ? props.dateStart
                                : null,
                            date_end: !checkDateParams ? props.dateEnd : null,
                        };
                    }
                }
            };

            dispatch(activeItemsSlice.actions.getAllActiveItemsPending());

            if (checkIdsParams && checkDateParams) {
                const { data } = await authAxios(dispatch).post(
                    `/api/items?page=${props.page}&paginate=${props.paginate}&search=${props.search}&status=${props.status}&company_id=${userCompanyId}`
                );

                responseData = data;
            } else {
                const { data } = await authAxios(dispatch).post(
                    `/api/items?page=${props.page}&paginate=${props.paginate}&search=${props.search}&status=${props.status}&company_id=${userCompanyId}`,
                    requestDataWithParams()
                );

                responseData = data;
            }

            dispatch(
                activeItemsSlice.actions.getAllActiveItemsFulfilled({
                    activeItems: responseData.data,
                    totalItems: responseData.pagination.total,
                })
            );

            if (responseData.data.length === 0) {
                dispatch(
                    additionalFilterSlice.actions.setAdditionalFilter({
                        page: Number(responseData.pagination.total_pages),
                        paginate: Number(responseData.pagination.per_page),
                    })
                );
            }
        } catch (error: any) {
            console.log(error)
            dispatch(
                activeItemsSlice.actions.getAllActiveItemsRejected(
                    error.data.message
                )
            );
        }
    };
};

export const getActiveItemById = (id: number) => {
    return async (dispatch: Dispatch): Promise<void> => {
        try {
            dispatch(activeItemsSlice.actions.getActiveItemPending());

            const { data } = await authAxios(dispatch).get(`/api/items/${id}`);

            dispatch(
                activeItemsSlice.actions.getActiveItemFulfilled({
                    activeItem: data,
                    activeHierarchy: [],
                    photos: []
                })
            );
        } catch (error: any) {
            dispatch(
                activeItemsSlice.actions.getActiveItemRejected(
                    error.data.message
                )
            );
        }
    };
};

export const getRetailerItemByGtin = (gtin: string) => {
    return async (dispatch: Dispatch): Promise<void> => {
        try {
            //dispatch(activeItemsSlice.actions.getActiveItemPending());

            const { data } = await authAxios(dispatch).get(`/api/retailer/product/${gtin}`);

            dispatch(
                activeItemsSlice.actions.getActiveItemFulfilled({
                    activeItem: data.item,
                    activeHierarchy: data.hierarchies,
                    photos: data.photos
                })
            );
        } catch (error: any) {
            dispatch(
                activeItemsSlice.actions.getActiveItemRejected(
                    error.data.message
                )
            );
        }
    };
};

export const changeActiveItemsStatus = (body: number[]) => {
    return async (dispatch: Dispatch): Promise<void> => {
        try {
            dispatch(activeItemsSlice.actions.changeStatusActiveItemPending());

            await authAxios(dispatch).post(`/api/items/change-status`, {
                array_ids: body,
                status: 0,
            });

            dispatch(
                activeItemsSlice.actions.clearActiveItemsForDeactivationDeletion()
            );
            dispatch(
                modalsSlice.actions.setIsChangeItemsDeletionActivationActions(
                    false
                )
            );
        } catch (error: any) {
            dispatch(
                activeItemsSlice.actions.changeStatusActiveItemRejected(
                    error.data.message
                )
            );
        }
    };
};

export const deleteActiveItems = (body: number[]) => {
    return async (dispatch: Dispatch): Promise<void> => {
        const config = {
            data: { array_ids: body },
        };

        try {
            dispatch(activeItemsSlice.actions.deleteActiveItemsPending());

            await authAxios(dispatch).delete(`/api/items/delete`, config);

            dispatch(
                activeItemsSlice.actions.clearActiveItemsForDeactivationDeletion()
            );
            dispatch(
                modalsSlice.actions.setIsChangeItemsDeletionActivationActions(
                    false
                )
            );
        } catch (error: any) {
            dispatch(
                activeItemsSlice.actions.deleteActiveItemsRejected(
                    error.data.message
                )
            );
        }
    };
};

import { combineReducers } from "@reduxjs/toolkit";
import { activeItemsSlice } from "./activeItems";
import { activeProductsSlice } from "./activeProducts";
import { additionalFilterSlice } from "./additionalFilter";
import { additionalFilterControlSlice } from "./additionalFilterControl";
import { authSlice } from "./auth";
import { companiesSlice } from "./companies";
import { draftItemsSlice } from "./draftItems";
import { draftProductsSlice } from "./draftProducts";
import { filterSlice } from "./filter";
import { filterControlSlice } from "./filterControl";
import { itemFormSlice } from "./itemForm";
import { itemInfoSlice } from "./itemInfo";
import { itemInfosSlice } from "./itemInfos";
import { modalsSlice } from "./modals";
import { optionListsSlice } from "./optionLists";
import { productFormFilterSlice } from "./productFormFilter";
import { productFormFilterControlSlice } from "./productFormFilterControl";
import { uploadSlice } from "./upload";
import { usersSlice } from "./users";

export const rootReducer = combineReducers({
  auth: authSlice.reducer,
  companies: companiesSlice.reducer,
  filter: filterSlice.reducer,
  filterControl: filterControlSlice.reducer,
  additionalFilter: additionalFilterSlice.reducer,
  additionalFilterControl: additionalFilterControlSlice.reducer,
  productFormFilter: productFormFilterSlice.reducer,
  productFormFilterControl: productFormFilterControlSlice.reducer,
  modals: modalsSlice.reducer,
  users: usersSlice.reducer,
  draftItems: draftItemsSlice.reducer,
  activeItems: activeItemsSlice.reducer,
  optionLists: optionListsSlice.reducer,
  activeProducts: activeProductsSlice.reducer,
  draftProducts: draftProductsSlice.reducer,
  itemForm: itemFormSlice.reducer,
  itemInfo: itemInfoSlice.reducer,
  itemInfos: itemInfosSlice.reducer,
  upload: uploadSlice.reducer,
});

export const actions = {
  ...authSlice.actions,
  ...companiesSlice.actions,
  ...filterSlice.actions,
  ...filterControlSlice.actions,
  ...modalsSlice.actions,
  ...usersSlice.actions,
  ...draftItemsSlice.actions,
  ...additionalFilterSlice.actions,
  ...additionalFilterControlSlice.actions,
  ...activeItemsSlice.actions,
  ...optionListsSlice.actions,
  ...activeProductsSlice.actions,
  ...draftProductsSlice.actions,
  ...productFormFilterSlice.actions,
  ...productFormFilterControlSlice.actions,
  ...itemFormSlice.actions,
  ...uploadSlice.actions,
};
